<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  mounted() {
    // 用于外部监听键盘事件
    window.addEventListener('keyup', function (event) {
      if (event.keyCode == 27) {
        parent.postMessage(
          {
            type: 'esc',
          },
          '*'
        )
      }
    })
  },
}
</script>

<style>
</style>
