import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router)
const vueRouter = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PATH,
  routes: [
    {
      path: '/',
      name: '404',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/components/error-page/404')
    },
    {
      path: '/mjkjDpIndex',
      name: 'list',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/page/index')
    }, {
      path: '/create',
      name: 'create',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/page/create')
    }, {
      path: '/build/:id',
      name: 'build',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/page/build')
    }, {
      path: '/view/:id',
      name: 'view',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/page/view')
    },
    {
      path: '*',
      redirect: '/'//定位转向，定向到/404路径
    },
  ]
})
export default vueRouter;